<!--标签维护-->
<template>
  <div class="m-grid m-grid-page p-tag">
    <section class="m-grid-box_column m-grid-box_col-6">
      <section class="m-grid-search">
        <el-input
          v-model="searchData.tagName"
          class="m-grid-input"
          clearable
          placeholder="搜索标签"
        ></el-input>
        <el-button class="m-grid-btn" type="primary" @click="onSearch()"
        >查询
        </el-button
        >
        <el-button class="m-grid-btn" plain type="primary" v-can="'config_tag:add'" @click="onAdd"
        >新建标签
        </el-button
        >
        <el-button class='m-grid-btn' plain type="primary" @click="onSort" v-can="'config_attribute:add'">排序</el-button>
      </section>
      <section class="m-grid-list">
        <grid
          ref="list"
          :columns="columns"
          :datas="itemSource"
          :onPageChanged="onSearch"
          :page-index="pagination.page"
          :page-size="pagination.limit"
          :selection="false"
          :total-count="pagination.total"
          headerHeight="35"
          type="base"
          @onCellClicked="onCellClicked"
        >
        </grid>
      </section>
    </section>

    <section class="m-grid-box_column m-grid-box_col-4 m-grid_margin-top--40">
      <section class="m-grid-title">
        <p>
          <b>标签值列表</b>
        </p>
      </section>
      <section class="m-grid-list">
        <grid
          ref="tagValueList"
          :columns="tagValueColumns"
          :datas="valueList"
          :selection="false"
          :suppressDragLeaveHidesColumns="true"
          :suppressPaginationPanel="false"
          headerHeight="35"
          type="base"
        >
        </grid>
      </section>
    </section>

    <tag-edit-view
      ref="tagEditView"
      @onEditConfig="onEditConfig"
    ></tag-edit-view>

    <el-dialog
        v-el-drag-dialog
        :close-on-click-modal="false"
        title="标签排序"
        :visible.sync="sortVisible"
    >
      <DictSort code="Lable" />
    </el-dialog>
  </div>
</template>

<script>
import {columns, tagValueColumns} from "./modules/tagView/config";
import {
  listDataMap,
  dateNameArr,
  editListMap,
  searchDataMap,
} from "./modules/tagView/dataMap";
import TagEditView from "./modules/tagView/TagEditView";
import {
  getTagList,
  getTagValueList,
  editTag,
  saveTag,
  deleteTag,
} from "@/api/config";
import DictSort from "@/views/config/modules/dict/DictSort";

export default {
  name: "configTag",
  components: {
    TagEditView,
    DictSort
  },
  data() {
    return {
      columns: columns,
      tagValueColumns: tagValueColumns,
      itemSource: [],
      valueList: [],
      pagination: {
        page: 1,
        size: 20,
        total: 0,
      },
      searchData: {
        tagName: "",
      },
      selectItemId: null,
      isEdit: false,
      selectItem: null,
      sortVisible: false
    };
  },
  methods: {
    async onInit() {
      await this.onSearch();
      const {itemSource} = this;
      if (itemSource.length !== 0) {
        const firstItem = itemSource[0];
        await this.onSelectTag(firstItem.Id);
      }
    },

    async onSearch(page = 1) {
      const {pagination, searchData} = this;
      const sendData = {
        Page: page,
        Limit: pagination.size,
      };
      Object.entries(searchDataMap).forEach(([key, value]) => {
        sendData[value] = searchData[key];
      });
      const data = await getTagList(sendData); // 请求接口

      pagination.page = data.Page;
      pagination.total = data.Total;
      this.itemSource = data.Items;
    },

    // 选中
    onCellClicked(row) {
      const {data} = row;
      this.onSelectTag(data.Id);
    },

    async onSelectTag(selectId) {
      await this.setSelected(selectId);
      this.valueList = await this.onValueList(selectId);
    },
    setSelected(selectId) {
      this.$refs.list.forEachNode((node) => {
        const {data} = node;
        if (data.Id === selectId) {
          return node.setSelected(true);
        } else {
          return node.setSelected(false);
        }
      });
    },

    async onValueList(selectId) {
      const params = {
        Id: selectId,
      };
      const data = await getTagValueList(params);
      let items = data.LabelItemLines;

      let newItem = [];
      if (items.length) {
        newItem = this.getListData(items, editListMap);
        newItem.forEach((item, index) => {
          dateNameArr.forEach((key) => {
            item[key] = this.formatDate(item[key], "yyyy-MM-dd hh:mm");
          });
          item.rowIndex = index + 1;
        });
      }
      return newItem;
    },

    onAdd() {
      this.isEdit = false;
      this.$refs.tagEditView.onOpen(null);
    },

    onSort() {
      const items = this.itemSource || []
      if (items.length <= 1) {
        this.$message.warning('大于1条才能排序')
        return
      }
      this.sortVisible = true
    },

    async onEditConfig(data) {

      let {isEdit} = this;
      let message = "";
      let selectId = 0;
      if (isEdit) {
        await editTag(data);
        selectId = data.Id;
        message = "标签编辑成功";
      } else {
        selectId = await saveTag(data);
        message = "标签新增成功";
      }
      this.$message.success(message);
      await this.onSearch(this.pagination.page);
      await this.onSelectTag(selectId);

    },

    // 表格组件处理
    // 初始化列表
    initColumns() {
      const self = this;
      const OriginColumn = columns.find((item) => item.field === "operation");
      if (OriginColumn) {
        OriginColumn.cellRendererParams = {
          buttons: self.setButtons,
        };
      }
      this.$refs.list.changeColumns(columns);
    },

    setButtons(params) {
      let data = params.data;
      let buttons = [];
      let self = this;
      const editBtn = {
        text: "编辑",
        click(params) {
          self.onOpenEdit(params.data);
        },
      };
      const deleteBtn = {
        text: "删除",
        click(params) {
          self.onOpenDelete(params.data);
        },
      };

      buttons.push(editBtn);
      if (!data.IsSystem && this.can('config_tag:edit')) {
        buttons.push(deleteBtn);
      }

      return buttons;
    },
    onOpenDelete(data) {
      if (data.IsSystem) {
        this.$message.warning("系统预设，不允许删除！");
        return;
      }
      const params = {
        Id: Number(data.Id),
      };
      this.$confirm("此操作将永久删除该标签, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await deleteTag(params);
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          await this.onInit();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    async onOpenEdit(data) {
      console.log(data)
      this.isEdit = true;
      const editItem = {
        id: data.Id,
        tagName: data.LabelName,
        isUse: data.Status,
        Multiple: data.Multiple,
        isReadonly: data.IsReadonly,
        itemSource: await this.onValueList(data.Id),
      };
      this.$refs.tagEditView.onOpen(editItem);
    },
  },
  created() {
    this.onInit();
  },
  mounted() {
    this.initColumns();
  },
  watch: {},
};
</script>
