// 标签列头配置
const columns = [
  {
    field: 'rowIndex',
    headerName: '序号',
    width: 70,
    cellClass: 't-c',
	  valueFormatter: ({ node: { rowIndex } }) => {
		  return rowIndex + 1;
	  }
  },

  {
    field: 'LabelName',
    headerName: '名称',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'LabelCount',
    headerName: '值个数',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'IsReadonly',
    headerName: '是否只读',
    editable: false,
    width: 80,
    cellClass: 't-c',
    valueFormatter(params) {
        const value = params.value
        return value ? '是' : '否'
    }
  },
  {
    field: 'Status',
    headerName: '是否启用',
    editable: false,
    width: 80,
    cellClass: 't-c',
    valueFormatter(params) {
      const value = params.value === 2
      return value ? '是' : '否'
    }
  },
  {
      field: 'Multiple',
      headerName: '是否多选',
      editable: false,
      cellClass: 't-c',
      valueFormatter(params) {
          const value = params.value
          return value ? '是' : '否'
      }
  },
  {
    field: 'CreatedAt',
    headerName: '创建时间',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'CreatedUser',
    headerName: '创建人',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'UpdatedAt',
    headerName: '更新时间',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'UpdatedUser',
    headerName: '更新人',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'operation',
    headerName: '操作',
    editable: false,
    width: 100,
    cellClass: 't-c',
    cellRendererFramework: 'GridCellButtonRender',
  }
]

// 标签值猎头配置
const tagValueColumns = [
  {
    field: 'rowIndex',
    headerName: '序号',
    width: 70,
    cellClass: 't-c'
  },
  {
    field: 'tagValueName',
    headerName: '值名称',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'tagValue',
    headerName: '值',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'createDate',
    headerName: '创建时间',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'createMan',
    headerName: '创建人',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'updateDate',
    headerName: '更新时间',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'updateMan',
    headerName: '更新人',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'isUse',
    headerName: '是否启用',
    editable: false,
    width: 100,
    cellClass: 't-c',
    valueFormatter(params) {
      const value = params.value
      return value === 2 ? '是' : '否'
    }
  }
]

// 编辑标签配置
const tagValEditColumns = [
  {
    field: 'rowIndex',
    headerName: '序号',
    width: 70,
    cellClass: 't-c',
    editable: false
  },
  {
    field: 'tagValueName',
    headerName: '值名称',
    editable: true,
    cellClass: 't-c'
  },
  {
    field: 'tagValue',
    headerName: '值',
    editable: true,
    cellClass: 't-c'
  },
  {
    field: 'createDate',
    headerName: '创建时间',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'createMan',
    headerName: '创建人',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'updateDate',
    headerName: '更新时间',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'updateMan',
    headerName: '更新人',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'isUse',
    headerName: '是否启用',
    editable: false,
    width: 100,
    cellClass: 't-c',
    valueFormatter(params) {
      const value = params.value
      return value ? '是' : '否'
    }
  }
]

const defaultData = {
  RowIndex: 0,
  id: 0,
  tagValueName: '',
  tagValue: '',
  createDate: null,
  createName: null,
  updateDate: null,
  updateName: null,
  isUse: 2,
}

const isUseOptions = [
  {key: 1, value: '否'},
  {key: 2, value: '是'}
]

const isReadonlyOptions = [
  {key: false, value: '否'},
  {key: true, value: '是'}
]

const isSelectOptions = [
		{key: true, value: '是'},
		{key: false, value: '否'}
]

export {
  columns,
  tagValueColumns,
  tagValEditColumns,
  defaultData,
  isUseOptions,
  isReadonlyOptions,
	isSelectOptions
}
