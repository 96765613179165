const listDataMap = {
  "id": 'Id',
  'tagName': 'LabelName',
  'tagValueNum': 'LabelCount',
  'createDate': 'CreatedAt',
  'createMan': 'CreatedUser',
  'updateDate': 'UpdatedAt',
  'updateMan': 'UpdatedUser',
  'isUse': 'Status',
  'isReadonly': 'IsReadonly',
  'isSystem':'IsSystem'
}
const editListMap = {
  id: 'DictItemLineID',
  tagValueName: 'ItemName',
  tagValue: 'ItemValue',
  createDate: 'CreatedAt',
  createMan: 'CreatedUser',
  updateDate: 'UpdatedAt',
  updateMan: 'UpdatedUser',
	isUse: 'IsActive',
  isReadonly: 'IsReadonly',
	Multiple: 'Multiple',
}

const paramsListMap = {
  id: 'DictItemLineID',
  tagValueName: 'ItemName',
  tagValue: 'ItemValue',
  isUse: 'IsActive',
}
const editItemMap = {
  id: 'Id',
  tagName: 'LabelName',
  isUse: "Status",
  isReadonly: 'IsReadonly',
	Multiple: 'Multiple',
  itemSource: 'DictItemLines'
}

const dateNameArr = [
  'createDate',
  'updateDate',
]

const searchDataMap = {
  'tagName': 'LabelName'
}

export {
  listDataMap,
  editListMap,
  dateNameArr,
  editItemMap,
  paramsListMap,
  searchDataMap
}
