<template>
  <div class="c-tag-view-dialog">
    <el-dialog
      v-el-drag-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="visible"
      @dragDialog="handleDrag"
    >
      <section class="c-tag-view-dialog-header">
        <div>
          <znl-input
            :border="true"
            :clearable="true"
            :height="32"
            placeholder=""
            title="标签:"
            title-width="60px"
            v-model="editItem.tagName"
            type="text"
            width="400px"
          >
          </znl-input>
        </div>
        <div class="m-margin--top-10">
          <znl-input
            :border="true"
            :clearable="true"
            :height="32"
            :select-options="isUseOptions"
            form-type="select"
            placeholder=""
            title="是否启用:"
            title-width="60px"
            v-model="editItem.isUse"
            width="400px"
          >
          </znl-input>
        </div>
        <div class="m-margin--top-10">
          <znl-input
              v-model="editItem.isReadonly"
              :border="true"
              :clearable="true"
              :height="32"
              :select-options="isReadonlyOptions"
              form-type="select"
              placeholder=""
              title="是否只读:"
              title-width="60px"
              width="400px"
          >
          </znl-input>
        </div>
        <div class="m-margin--top-10">
          <znl-input
              v-model="editItem.Multiple"
              :border="true"
              :clearable="true"
              :height="32"
              :select-options="isSelectOptions"
              form-type="select"
              placeholder=""
              title="是否多选:"
              title-width="60px"
              width="400px"
          >
          </znl-input>
        </div>
      </section>
      <section class="m-margin--top-10 m-grid c-tag-view-dialog-box">
        <div class="m-grid-search">
          <el-button class="m-grid-btn" type="primary" @click="addData"
            >新建</el-button
          >
          <el-button class="m-grid-btn" plain type="primary" @click="deleteData"
            >删除</el-button
          >
        </div>
        <div class="m-margin--top-10 m-grid-list">
          <grid
            ref="list"
            :columns="columns"
            :datas="editItem.itemSource"
            :selection="true"
            :suppressDragLeaveHidesColumns="true"
            :suppressPaginationPanel="false"
            headerHeight="35"
            type="edit"
          >
          </grid>
        </div>
      </section>
      <section class="m-margin--top-10 m-dialog-footer">
        <el-button type="primary" @click="onSave">保存</el-button>
        <el-button @click="visible = false">取消</el-button>
      </section>
    </el-dialog>
  </div>
</template>
<script>
import elDragDialog from "@/directive/el-drag-dialog"; // base on element-ui
import { tagValEditColumns, defaultData, isUseOptions, isSelectOptions,isReadonlyOptions } from "./config";
import { paramsListMap, editItemMap } from "./dataMap";
export default {
  name: "TagEditView",
  directives: {
    elDragDialog,
  },
  data() {
    return {
      visible: false,
      title: "新增",
      columns: tagValEditColumns,
      isEdit: false,
      editItem: {
        id: 0,
        tagName: "",
        isUse: null,
        Multiple: false,
        isReadonly: false,
        itemSource: [],
      },
      isUseOptions: isUseOptions,
      isSelectOptions: isSelectOptions,
      isReadonlyOptions: isReadonlyOptions,
      cancelLable: [],
    };
  },
  methods: {
    onOpen(data) {
      this.isEdit = data !== null;
      this.title = data !== null ? "编辑" : "新增";
      this.editItem = {
        id: 0,
        tagName: "",
        isUse: null,
        Multiple: false,
        isReadonly: false,
        itemSource: [],
      }
      if (data === null) {
        Object.assign(this.$data.editItem, this.$options.data().editItem);
      } else {
        Object.keys(this.editItem).forEach((key) => {
          this.editItem[key] = data[key];
        });
      }
      this.visible = true;
    },

    // 插入序号
    initRowIndex(list) {
      if (list.length === 0) {
        return [];
      }
      const newList = [];
      list.forEach((item, index) => {
        item.rowIndex = index + 1;
        newList.push(item);
      });
      return newList;
    },

    addData() {
      let line = this.editItem.itemSource;
      let data = Object.assign({}, defaultData);
      line.push(data);
      this.itemSource = this.initRowIndex(line);
    },

    deleteData() {
      let rows = this.$refs.list.getSelectedRows();
      rows.forEach((item) => {
        this.cancelLable.push(item.id);
      });
      let RowIndexS = rows.map((item) => item.rowIndex);
      let lines = this.editItem.itemSource.filter(
        (item) => !RowIndexS.includes(item.rowIndex)
      );
      this.editItem.itemSource = this.initRowIndex(lines);
    },

    onSave() {
      // 处理列表
      let paramsList = [];
      if (this.editItem.itemSource.length !== 0) {
        this.editItem.itemSource.forEach((item) => {
          const newItem = this.setDataMap(item, paramsListMap, "save");
          paramsList.push(newItem);
        });
      }
      this.editItem.itemSource = Object.assign([], paramsList);
      let params = this.setDataMap(this.editItem, editItemMap, "save");
      params.CancelLines = this.cancelLable;
      this.$emit("onEditConfig", params);
      this.visible = false;
    },

    handleDrag() {
      // 拖拽事件
    },
  },
  created() {},
};
</script>
<style lang="scss">
.p-tag {
  .c-tag-view-dialog-box {
    max-height: 400px;
  }
}
</style>
